*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.MqZHCq_header {
  color: #333;
  margin-top: 20px;
  margin-bottom: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

button {
  all: unset;
}

.mOk7Wq_selectRoot {
  height: var(--number-box-height);
}

.mOk7Wq_SelectTrigger {
  color: var(--number-box-font-color);
  border: 1px solid var(--number-box-border-color);
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 60px;
  height: 35px;
  margin-left: 10px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  display: inline-flex;
}

.mOk7Wq_SelectTrigger:hover {
  background-color: var(--mauve-3);
}

.mOk7Wq_SelectTrigger:focus {
  box-shadow: 0 0 0 2px #000;
}

.mOk7Wq_SelectTrigger[data-placeholder], .mOk7Wq_SelectIcon {
  color: var(--number-box-font-color);
}

.mOk7Wq_SelectContent {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.mOk7Wq_SelectViewport {
  padding: 5px;
}

.mOk7Wq_SelectItem {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.mOk7Wq_SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.mOk7Wq_SelectItem[data-highlighted] {
  background-color: var(--hover-button-color);
  color: #fff;
  outline: none;
}

.mOk7Wq_SelectLabel {
  color: var(--mauve-11);
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.mOk7Wq_SelectSeparator {
  background-color: var(--violet-6);
  height: 1px;
  margin: 5px;
}

.mOk7Wq_SelectItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.mOk7Wq_SelectScrollButton {
  color: var(--violet-11);
  cursor: default;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

button {
  all: unset;
}

.selectRoot {
  height: var(--number-box-height);
}

.SelectTrigger {
  color: var(--number-box-font-color);
  border: 1px solid var(--number-box-border-color);
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 60px;
  height: 35px;
  margin-left: 10px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  display: inline-flex;
}

.SelectTrigger:hover {
  background-color: var(--mauve-3);
}

.SelectTrigger:focus {
  box-shadow: 0 0 0 2px #000;
}

.SelectTrigger[data-placeholder], .SelectIcon {
  color: var(--number-box-font-color);
}

.SelectContent {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  background-color: var(--hover-button-color);
  color: #fff;
  outline: none;
}

.SelectLabel {
  color: var(--mauve-11);
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.SelectSeparator {
  background-color: var(--violet-6);
  height: 1px;
  margin: 5px;
}

.SelectItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.SelectScrollButton {
  color: var(--violet-11);
  cursor: default;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

/*# sourceMappingURL=index.8f327978.css.map */
